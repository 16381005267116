import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "analytics-nav-menu__wrap" }
const _hoisted_2 = { class: "analytics-nav-menu__container" }
const _hoisted_3 = { class: "analytics-nav-menu__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/admin/analytics/home",
      class: "analytics-nav-menu__container-menu"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Home")
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "analytics-nav-menu__group-title" }, "Instructors", -1)),
      _createVNode(_component_router_link, {
        to: "/admin/analytics/instructor/skills-tracking",
        class: "analytics-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Skills Tracking")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/analytics/instructor/number-of-next-level",
        class: "analytics-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Number of students moved to the next level ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/analytics/instructor/not-tracking-skills",
        class: "analytics-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" Instructors not tracking skills ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/analytics/instructor/most-reviewed-instructors",
        class: "analytics-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" Most reviewed instructors ")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "analytics-nav-menu__group-title" }, "Students", -1)),
      _createVNode(_component_router_link, {
        to: "/admin/analytics/students/fast-learners",
        class: "analytics-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("Fast learners")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/analytics/students/slow-movers",
        class: "analytics-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode(" Slow movers ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/analytics/students/students-moved-up-to-next-level",
        class: "analytics-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("Students moved up to next level")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/analytics/students/time-spent-in-program",
        class: "analytics-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode("Time spent in the program")
        ])),
        _: 1
      })
    ])
  ]))
}